import { CMessage } from '@/components/CMessage';
import type { AxiosRequestConfig, AxiosResponse,AxiosRequestHeaders } from 'axios'
import axios from 'axios'
import { getPublicApiParams } from './config';

const instance = axios.create({
    baseURL: '/proxyApi',
    timeout: 10000,    //超时配置
    withCredentials: true,  //跨域携带cookie
});

// 添加请求拦截器
instance.interceptors.request.use(async function (config) {
    let { url, method } = config

    // 获取公共参数
    const { bd, token } = await getPublicApiParams()

    // console.log('process.env.NODE_ENV',process.env.NODE_ENV,config.url);
    
    // if(process.env.NODE_ENV !== 'production'){
    //     config.url = config.url.replace('/proxyApi', '');
    // }

    // 不需要添加v2前缀
    if (config?.data?.notNeedV2 || config?.params?.notNeedV2) {
    } else {
        config.url = '/v2' + url
    }
    if (method == 'get') {
        config.params = { ...bd, ...config.params, token }
    } else {
        config.params={
            // timestamp:config?.data?.timestamp? config?.data?.timestamp :bd?.timestamp
            timestamp:bd?.timestamp
         }
        config.data = { ...bd, ...config.data, token }
    }

    config.headers = {
        Accept: 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
    } as unknown as AxiosRequestHeaders 
    // console.log(config)
    return config;
}, function (error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

// 添加响应拦截器
instance.interceptors.response.use(function (response: AxiosResponse) {
    if (response.data.code != 200) {
        if (response.data.code == 403100) {
            localStorage.clear()
            // location.href = '/login'
        }
        CMessage.error(response.data?.message || response.data?.msg || 'error')
    }
    // 对响应数据做点什么
    return response.data;
}, function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});


export const http = {
    get<T = any>(url: string, config?: AxiosRequestConfig): Promise<API.Result<T>> {
        return instance.get(url, config)
    },

    post<T = any>(url: string, data?: object, config?: AxiosRequestConfig): Promise<API.Result<T>> {
        return instance.post(url, data, config)
    },

    put<T = any>(url: string, data?: object, config?: AxiosRequestConfig): Promise<API.Result<T>> {
        return instance.put(url, data, config)
    },

    delete<T = any>(url: string, config?: AxiosRequestConfig): Promise<API.Result<T>> {
        return instance.delete(url, config)
    }
}
