import { lazy } from 'react';

const Layout = lazy(() => import('@/pages/index'));
const Home = lazy(() => import('@/pages/home/index'));
const Login = lazy(() => import('@/pages/login/index'));
const Setting = lazy(() => import('@/pages/setting/index'));
const AccountSecurity = lazy(() => import('@/pages/accountSecurity/index'));
const DestroyAccountlazy = lazy(() => import('@/pages/destroyAccount/index'));
const Prod = lazy(() => import('@/pages/prod/index'));
const DownSignApp = lazy(() => import('@/pages/downSignApp/index'));
const FlowChart = lazy(() => import('@/pages/flowChart/index'));
const Card = lazy(() => import('@/pages/bankcard/index'));
const PrivacyMenu = lazy(() => import('@/pages/privacymenu/index'));
const OrderDetails = lazy(() => import('@/pages/orderdetails/index'));
const HelpCenter = lazy(() => import('@/pages/helpcenter/index'));
const Identity = lazy(() => import('@/pages/info/identity/index'));
const BasicInfo = lazy(() => import('@/pages/info/basic/index'));
const Emergency = lazy(() => import('@/pages/info/emergency/index'));
const SetinnerHtml = lazy(() => import('@/components/SetinnerHtml/index'))
const Loan = lazy(() => import('@/pages/loan/index'));
const Mine = lazy(() => import('@/pages/mine/index'));
const Iframe = lazy(() => import('@/pages/iframe/index'));
const Location = lazy(() => import('@/pages/info/location/index'));
const Question = lazy(() => import('@/pages/question/index'));
const Work = lazy(() => import('@/pages/info/wrok/index'));
const NotFound = lazy(() => import('@/pages/404/index'));
const Repayment = lazy(() => import('@/pages/repayment/index'));
const RepaymentBank = lazy(() => import('@/pages/repaymentBank/index'));
const RepaymentShop = lazy(() => import('@/pages/repaymentShop/index'));
const RepaymentVA = lazy(() => import('@/pages/repaymentVA/index'));
const RepaymentPayNet = lazy(() => import('@/pages/repaymentPayNet/index'));
const Transfer = lazy(() => import('@/pages/transfer/index'));
const BeforeQuestion = lazy(() => import('@/pages/beforeQuestion/index'));


/**
 * 嵌套路由使用Layout包裹
 * Layout附带登陆鉴权
 */
export const routesConfig: RouteType[] = [
    {
        path: "/", Components: Layout,
        children: [
            { path: "home", Components: Home }, // 首页
            { path: "identity", Components: Identity }, // 上传身份证
            { path: "basic", Components: BasicInfo }, // 基础信息
            { path: "emergency", Components: Emergency }, // 紧急联系人
            { path: "location", Components: Location }, // 暂不使用
            { path: 'work', Components: Work }, // 工作信息
            { path: 'prod', Components: Prod }, // 产品页
            { path: 'bankcard', Components: Card }, // 添加银行卡
            { path: 'loan', Components: Loan },
            { path: 'question', Components: Question }, // 问卷与小作文
            { path: 'beforeQuestion', Components: BeforeQuestion }, // 问卷与小作文
        ]
    },
    {
        path: '/login',
        Components: Login
    },
    {
        path: '/accountSecurity',
        Components: AccountSecurity

    },
    {
        path: '/destroyAccount',
        Components: DestroyAccountlazy

    },
    {
        path: '/repayment',
        Components: Repayment

    }, {
        path: '/setting',
        Components: Setting

    },
    {
        path: '/downSignApp',
        Components: DownSignApp
    },
    {
        path: '/flowChart',
        Components: FlowChart
    },
    {
        path: '/orderdetails',
        Components: OrderDetails
    },
    {
        path: '/privacymenu',
        Components: PrivacyMenu
    },
    {
        path: '/helpcenter',
        Components: HelpCenter
    },
    {
        path: '/loan',
        Components: Loan
    },
    {
        path: '/mine',
        Components: Mine
    },
    {
        path: '/iframe',
        Components: Iframe
    },
    {
        path: '/repaymentbank',
        Components: RepaymentBank
    },
    {
        path: '/repaymentva',
        Components: RepaymentVA
    },
    {
        path: '/repaymentpaynet',
        Components: RepaymentPayNet
    },
    {
        path: '/repaymentshop',
        Components: RepaymentShop
    },

    {
        path: '/SetinnerHtml/:title/:comp',
        Components: SetinnerHtml
    },
    {
        path: '/transfer',
        Components: Transfer
    },
    {
        path: '/404',
        Components: NotFound
    }
]