import { PaletteOptions } from "@mui/material/styles"
import { TH } from '@/utils/config/TH'
import { MX } from '@/utils/config/MX'
import { PK } from '@/utils/config/PK'

const defaultTheme: PaletteOptions | Required<CustomPalette> = {
    primary: {
        main: '#C3A475',
    },
    secondary: {
        main: '#78541C'
    },
    text: {
        primary: '#7F5B24',
        secondary: '#C3A475',
    },
    // 黑色文字
    blackText: {
        primary: '#0E2336',
        secondary: '#656565',
        desc: '#999999'
    },
    button: {
        primary: ['#685B47', '#4E412D'],
        secondary: ['#F7EDDF', '#F7EDDF'],
        disable: '#CBCBCB'
    },
    buttonText: {
        primary: ['#E3C394', '#FFF8EE'],
        disable: '#ffffff'
    },
    amount: {
        primary: ['#685B47', '#4E412D'],
        secondary: ['#FFFFFF', '#685B47']
    },
    FZ: {
        sBase: 26,
        base: 28,
        lBase: 30,
        mBase: 32,
        title: 36,
        submit: 38,
        small: 24
    },
    customBg: {
        default: '#f3f3f3',
        paper: 'rgba(216, 216, 216, 0.2)'
    }
}

/**
 * 配色使用默认、本地化配置、app传参
 * @param config
 * @returns
 */
export const getLocal = (config: CustomPalette = {}): CountryConfigType => {
    let local: CountryConfigType
    switch (REACT_APP_ENV) {
        case 'TH':
            local = TH;
            break;
        case 'MX':
            local = MX;
            break;
        case 'PK':
            local = PK;
            break;
        default:
            local = TH;
            break;
    }
    return {
        ...local,
        palette: { ...defaultTheme, ...local.palette, ...config }
    }
}
