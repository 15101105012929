import { childrenNum, dontNeedLoginPath, payDay } from "../Default";
import {fm} from "@/locals/index";

/**个人信息 */
//学历
const educations = [
    "Undergraduate And Above",
    "HIgh School ",
    "Secondary School ",
    "Junior HighSchool And Below",
]
//婚姻状况
const maritalStatus = ["Married", "Unmarried"]
//性别
const gender = ['男', '女', '第三性别']
//居住类型
const liveType = ['自有房产', '租赁', '其他']
//居住时长
const liveLength = ['3个月', '6个月', '1年', '2年', '2年以上']
// 贷款用途
const loanPurposeList = ['日常使用', '健康', '维修', '投资', '儿童学费', '其他（必须由客户指定）']

/**紧急联系人 */
const relation = ['父亲', '母亲', '伴侣',]
const relation_other = ['兄弟姐妹', '朋友']

/**发薪方式 */
const getMoneyType = ["Semanal", "Salario diario", "Mensual", 'Quincenal']
const monthlyIncome = ["10,000 Below","10,000~15,000","15,000~20,000","20,000~30,000","30,000~50,000","50,000 Above",]

/**工作信息 */
const jobList = ['警察', '家庭主妇', '企业主','教师', '牧民','力工','政府承包商','机械师','司机','批发商','工程师','IT','服务员','研究人员','会计','营销人员','顾问','公务员','行政人员','医务人员','渔夫','船员','非正式官员','经纪人','厨师','安全官员','饲养员','建筑师','行政官','军事人员','设计师','大学生','艺术家','律师','退休人员','政府官员','保安','临时雇员','飞行员','医生']



export const PK: CountryConfigType = {
    aes_key: 7843565827918888,
    adsKey: 'AW-11040999643/QnqMCNWp74QYENuR4ZAp',
    facebookId: '536031615074228',
    download_url: '',
    web_url: 'https://www.moremoney999.com/',
    faceUrl: 'https://testplat.znuogame.com/?lang=es&origin=',
    title: 'Fides Credit',
    palette: {},
    gender: gender,
    imageUploadType: 'oss',
    education: educations,
    dontNeedLoginPath: [...dontNeedLoginPath],
    loanUseType: loanPurposeList,
    childrenNum: childrenNum,
    maritalStatus: maritalStatus,
    liveType: liveType,
    liveLength: liveLength,
    relation: relation,
    relationOther: relation_other,
    jobs: jobList,
    monthlyIncome: monthlyIncome,
    payDay: payDay,
    getMoneyType: getMoneyType,
    bankCardNum: '17',
    jobType:["Salaried", "Self Employer", "Student", "Others"],
}