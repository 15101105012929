import React, { useEffect } from 'react';
import { IntlProvider } from 'react-intl';
import intl from 'intl';
import { ThemeProvider } from '@mui/material/styles';
import RouterView from '@/router/index'
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import en from '@/locals/en_US'
import zh from '@/locals/zh_CN'
import th from '@/locals/th_TH'
import es from '@/locals/es-ES'
import { initWindowGlobal } from './utils/window';
import vconsole from 'vconsole'
new vconsole()
import GlobalMessage from '@/components/CMessage/index';
import CMLoading from './components/CLoading';
import ReactPixel from 'react-facebook-pixel';
import { install } from 'ga-gtag';

const message = {
    EN: en,
    MX: en,
    // MX: es,
    VN: zh,
    PE: zh,
    TH: th,
    PK: en,
    // PK: zh,
};

window.initWindowGlobal = initWindowGlobal
initWindowGlobal()

function App() {

    useEffect(() => {
        install(window.gtagKey)
        ReactPixel.init(window.ReactPixelKey)

        // 注册
        // if ('serviceWorker' in navigator) {
        //     navigator.serviceWorker
        //         .register('/sw.js')
        //         .then(() => { console.log('Service Worker Registered'); });
        // }

        // window.addEventListener("beforeinstallprompt", function (e) {
        //     // log the platforms provided as options in an install prompt
        //     console.log(e); // e.g., ["web", "android", "windows"]
        //     // e.userChoice.then(function (outcome) {
        //     //     console.log(outcome); // either "installed", "dismissed", etc.
        //     // }, handleError);
        // });

        document.getElementsByTagName('title')[0].innerText = window.title
        let icon = document.querySelector('link[rel="icon"]')
        if(icon){
            //@ts-ignore
            icon.href = window.iconHref
        }
    }, [])

    
    // window.addEventListener('replaceState', function (event) {
    //     console.log('event',event);
        
    // })



    return <IntlProvider locale={REACT_APP_ENV} messages={message[REACT_APP_ENV]}>
        <ThemeProvider theme={theme}>
            <LocalizationProvider dateAdapter={AdapterMoment}>
                <RouterView />
            </LocalizationProvider>
            {
                // 全局提示组件
                <>
                    <GlobalMessage />
                    <CMLoading />
                </>
            }
        </ThemeProvider>
    </IntlProvider>;
}

export default App;
