import { getLocal } from "./getLocal";
import { createTheme } from '@mui/material/styles';
import common from "@/common/common";
import { getDeviceInfo, getAppInfo, getAppsFlyerUID, getGPSInfo } from '@/common/Bridge'
import { CLoading } from "@/components/CLoading";


/**
 * 挂载window全局变量
 */
export async function initWindowGlobal(init?: InitConfig) {
    if (init?.token) {
        localStorage.setItem('token', init?.token)
    }
    window.REACT_APP_ENV = process.env?.REACT_APP_ENV || 'TH' // 环境变量
    window.localConfig = getLocal(init?.config || {}) // 本地化配置
    window.theme = createTheme({ // 主题配置
        palette: localConfig.palette,
    });
    window.startGpsFlag = false
    window.initAdvaceFlag = false
    window.initAfFlag = false
    window.initFaceBookSDKFlag = false
    window.isInApp = !!window.WebViewJavascriptBridge
    window.showLoading = () => CLoading.show()
    window.hiddenLoading = () => CLoading.hidden()
    window.setPxToVw = (pxValue: number) => {
        if (pxValue == 0) {
            return '0px'
        }
        var parsedVal = toFixed((pxValue / 750 * 100), 6);
        return parsedVal === 0 ? '0' : parsedVal + 'vw';
    }
    await common.InitializeAdvaceAndAf()
    await common.checkVersionUpdate()

    await getDeviceInfo()
    await getAppInfo()
    await getAppsFlyerUID()
    await getGPSInfo()
}

export function isSameUI(isSame: boolean = false) {
    return isSame ? 'Default' : REACT_APP_ENV
}

function toFixed(number, precision) {
    var multiplier = Math.pow(10, precision + 1),
        wholeNumber = Math.floor(number * multiplier);
    return Math.round(wholeNumber / 10) * 10 / multiplier;
}