const en = {
    '十八岁以下未成年用户无法获取贷款': 'Minors under 18 cannot obtain loans',
    '正规持牌金融机构': 'Regular licensed financial institutions',
    '您最高可激活额度': 'Your maximum activatable amount',
    '元': 'PKR',
    '激活额度': 'Loan',
    '年化费率最低': 'Lowest annualized rate',
    '借1万每日低至': 'Borrow 10,000 as low as per day',
    '填写资料': 'Submit Info',
    '评估额度': 'Evaluate',
    '立即放款': 'Get loan',
    '申请步骤': 'Application steps',
    '身份证正面': 'ID card front side',
    '上传您的身份证人像面': 'Upload your ID card portrait side',
    '身份证反面': 'ID card back side',
    '上传您的身份证国徽面': 'Upload your ID card national emblem side',
    '手持身份证拍摄': 'Take a photo holding your ID card',
    '上传您拿着身份证的照片': 'Upload a photo of you holding your ID card',
    '身份认证': 'Identity authentication',
    '下一步': 'Next',
    '基础信息': 'Basic information',
    '姓名': 'Name',
    '小名': 'Nickname',
    '学历': 'Education level',
    '请选择': 'Please select ',
    '市': 'City',
    '请输入': 'Please enter ',
    '保存': 'Save',
    '详细地址': 'Detailed address',
    '省份': 'Province',
    '戳我定位': 'Click me to locate',
    '家庭地址': 'Home address',
    '基本信息': 'Basic information',
    '子女数量': 'Number of children',
    '婚姻状况': 'Marital status',
    '贷款用途': 'Loan purpose',
    '居住类型': 'Residence type',
    '居住时长': 'Length of residence',
    '紧急联系人添加': 'Add emergency contact person',
    '直系亲属联系人': 'Direct relative contact person',
    '关系': 'Relationship',
    '手机号码': 'Phone number',
    '真实姓名': 'Real name',
    '紧急联系人1': 'Emergency contact person 1',
    '紧急联系人2': 'Emergency contact person 2',
    '工作信息': 'Work information',
    '职业': 'Occupation',
    '每月收入': 'Monthly income',
    '发薪日期': 'Payday',
    '公司名称': 'Company name',
    '公司电话': 'Company phone number',
    '公司地址': 'Company address',
    '借款天数': 'Loan days',
    '贷款金额': 'Repayment amount',
    '到期应还金额': 'Repayment amount',
    '收款信息': 'Collection information',
    '还未添加银行卡，快去添加吧': 'Bank card not added yet, add it quickly.',
    '添加银行卡': 'Add bank card',
    '立即借款': 'Borrow now',
    '请认真阅读并同意《借款协议》': 'Please read and agree to the “Loan Agreement” carefully.',
    '还款日': 'Repayment date',
    '收款银行卡号': 'Bank card number',
    '确认借款': 'Confirm',
    '放弃借款': 'Give up',
    '到账金额': 'Amount credited',
    '更多信息': 'More information',
    '订单详情': 'Order details',
    '等待放款': 'Waiting for loan disbursement',
    '订单已提交，将在12小时内处理完成，感谢您的耐心等待': 'Order has been submitted and will be processed within 12 hours. Thank you for your patience.',
    '订单编号': 'Order number',
    '去下载': 'Download',
    '保存二维码下载‘电子签约云平台’扫码可查看放款进度方便还款。点击下方去按钮跳转应用市场下载‘电子签约云平台’': 'Save the QR code to download “Electronic Signature Cloud Platform” and scan it to check the progress of the loan and facilitate repayment. Click the “Go” button below to jump to the application market to download “Electronic Signature Cloud Platform”.',
    '首页':'Home',
    '我':'Me',
    '服务费':'Service fee',
    '到期还款日':'Due date',
    '您输人的银行卡号码属于敏感个人信息，点击“确定”（以页面显示为准）代表您充分知悉并同意我们基于身份识别、借款服务、完成支付、银行卡鉴权的目的处理您的上述信息。如您拒绝同意，将影响您使用前述服务。':'Your bank card number is sensitive personal information. Click “OK” (based on page display) to indicate that you fully understand and agree that we process your above information for identity recognition, loan services, payment completion, and bank card authentication purposes. If you refuse to agree, it will affect your use of the above services.',
    '银行卡':'Bank card',
    '卡类别':'Card type',
    '银行卡名称':'Bank card name',
    '银行卡号':'Bank card number',
    '确认银行卡号':'Confirm bank card number',
    '持卡人姓名':'Cardholder name',
    '收款银行编码':'Collection bank code',
    '确认':'Confirm',
    '戳我去修改':'Click me to modify',
    '收款银行卡名称':'Collection bank card name',
    '收款银行卡卡号':'Collection bank card number',
    '收款信息有误？':'Is the collection information wrong?',
    '确认贷款订单':'Confirm loan order',
    '贷款订单已申请成功，再仅需1分钟完成签约合同即可放款':'The loan order has been successfully applied for, and it only takes one minute to complete the signing of the contract before the loan can be issued.',
    '申请成功':'Application successful',
    '合同托管至第三方专业的电子合同平台（“电子合同云平台App”）管理，在线签约合同仅需1分钟即可全部完成。':'The contract is managed by a third-party professional electronic contract platform (“eSigner App”), and the online signing of the contract can be completed in just one minute',
    '温馨提醒':'Reminder',
    '打开电子合同App签约':'Open eSigner App',
    '不知道操作签约合同？查看帮助':'Do not know how to operate a contract? view help',
    '订单信息':'Order information',
    '流程图':'Flow chart',
    '不放弃':"Don't give up",
    '您还差最后一步就有获得XXX元的机会，确定要放弃吗？':'You still have a chance to get XXX PKR in the last step, are you sure you want to give up?',
    '您还差最后一步就有获得':"You still have a chance to get ",
    '元的机会，确定要放弃吗？':'PKR in the last step, are you sure you want to give up?',
    // '您还差最后一步就有获得':"You're one last step away from getting",
    // '元的机会，确定要放弃吗？':'PKR opportunity, are you sure you want to give up?',
    '放弃':'Give up',
    '性别':'Gender',
    '0':'0',
    '1':'1',
    '2':'2',
    '3':'3',
    '4':'4',
    '5':'5',
    '5个以上':'More than 5',
    '日常使用':'Daily use',
    '健康':'Health',
    '维修':'Repair',
    '投资':'Investment',
    '儿童学费':'Children’s tuition fees',
    '其他（必须由客户指定）':'Other (must be specified by the customer)',
    '3个月':'3 months',
    '6个月':'6 months',
    '1年':'1 year',
    '2年':'2 years',
    '2年以上':'More than 2 years',
    '兄弟姐妹':'Siblings',
    '朋友':'Friends',
    '父亲':'Father',
    '母亲':'Mother',
    '伴侣':'Partner',
    '还款日期':'Repayment date',
    '剩余应还':'Remaining to be repaid',
    '网银转账':'Online banking transfer',
    '便利店oxxo付款':'Convenience store oxxo payment',
    '扫码支付':'Scan code payment',
    'VA账号付款':'VA account payment',
    '便利店PAYNET付款':'Convenience store PAYNET payment',
    '待还总额（元）':'Total amount to be repaid (PKR)',
    '还款倒计时':'Repayment countdown',
    '天':'Days',
    '去还款':'Repayment',
    '请选择支付方式':'Please select a payment method',
    '金额':'Amount',
    '请选择展期天数':'Please select the number of days for extension',
    '确定':'Confirm',
    '我的借款':'My loan application',
    '我的客服':'My customer service',
    '帮助中心':'Help center',
    '隐私政策':'Privacy policy',
    '关于我们':'About us',
    '设置':'Settings',
    '已认证':'Verified',
    '账号注销':'Account cancellation',
    '退出登录':'Logout',
    '填写验证码':'Verification code',
    '获取验证码':'Get verification code',
    '登录':'Login',
    '手机号':'Phone number',
    '填写手机号':'Fill in mobile number',
    '验证码':'Verification code',
    '请填写手机号':'Please fill in your phone number',
    '请填写验证码':'Please fill in the verification code',
    '请输入正确格式的有效值':'Please enter a valid value in the correct format',
    '欢迎使用':'Welcome',
    '申请展期':'Extension',
    '需要许可证':'Permit required',
    '出生日期':'Birthday',
    '证件号码':'ID number',
    '状态':'Status',
    '账号安全':'Account security',
    '注销后，以下信息会发生变化':'After logging out, the following information will change',
    '账户相关信息将会被删除，包括绑定信息(手机号、银行卡、身份证、微信、邮箱等)、交易、还款等订单信息(交易单、账单等).后续将无法再使用该账户交易。':'Account-related information will be deleted, including binding information (mobile phone number, bank card, ID card, WeChat, email, etc.), transaction, repayment and other order information (transaction slip, bill, etc.). The account will no longer be available for subsequent use trade.',
    '账户信息删除':'Account Information Delete',
    '账户相关信息将会被删除，包括绑定信息(手机号、银行卡、身份证、微信、邮箱等)、交易、还款等订单信息(交易单、账单等).后续将无法再使用该账户交易。相关权益(优惠券、购物金、红包、乐星、乐黑卡特权等)将会被清空，消费额度将会被清空，后续无法继续享用相关权益和消费额度。':'Account-related information will be deleted, including binding information (mobile phone number, bank card, ID card, WeChat, email, etc.), transaction, repayment and other order information (transaction slip, bill, etc.). The account will no longer be available for subsequent use trade. Relevant rights and interests (coupons, shopping rewards, red envelopes, Lexing, Lehei card privileges, etc.) will be emptied, and the consumption quota will be emptied, and the relevant rights and consumption quota will not be able to continue to be enjoyed in the future.',
    '相关权益删除':'Relevant rights and interests deleted',
    '后续将不再提供关于该账户的相关咨询和售后服务。':'In the future, relevant consultation and after-sales service for this account will no longer be provided.',
    '账户不可找回':'Account cannot be retrieved',
    '注销后账户将彻底删除，无法找回，代表你完全放弃了本账户相关的一切服务。':'After canceling, the account will be completely deleted and cannot be retrieved, which means that you have completely given up all services related to this account.',
    '不再提供咨询和售后服务':'Consultation and after-sales service are no longer provided',
    '注销后重新注册，可能会影响额度的获取':'Re-registration after cancellation may affect the quota acquisition',
    '为防范欺诈风险，若注销此账号后重新注册，新账号在本平台的授信申请审批及额度获取可能会受到影响':"In order to prevent the risk of fraud, if you cancel this account and re-register, the approval of the new account's credit application and credit limit acquisition on this platform may be affected",
    '暂不注销':"Don't log out",
    '开始注销':'Logout',
    '开始拍照':'Take photo',
    '选择照片':'Choose photo',
    '逾期天数':'Days Overdue',
    '请输入姓名':'please enter your name',
    '长度为18位数字':'length is 18 digits',
    '请输入证件号码':'Please enter the ID number',
    '请输入正确邮箱':'Please enter the correct email address',
    '联系人选择':'select contact',
    '长度为16或18位数字':'Length is 16 or 18 digits',
    '确认银行卡号与银行卡号不一致':'Confirm that the bank card number does not match the bank card number',
    '借款协议':'Loan Agreement',
    '您存在未完成订单，不能注销账号':'You have unfinished orders and cannot delete your account',
    '职业类型':'Type of jobs',
    '男':'male',
    '女':'female',
    '第三性别':'third gender',
    '自有房产':'own property',
    '租赁':'lease',
    '其他':'other',
    '警察': 'police',
    '家庭主妇': 'housewife',
    '企业主': 'Business owners',
    '教师': 'teacher',
    '牧民': 'herdsman',
    '力工': 'physical laborer',
    '政府承包商': 'Government Contractor',
    '机械师': 'machinist',
    '司机': 'driver',
    '批发商': 'wholesaler',
    '工程师': 'engineer',
    'IT': 'IT',
    '服务员': 'waiter',
    '研究人员': 'researcher',
    '会计': 'accounting',
    '营销人员': 'Marketing personnel',
    '顾问': 'adviser',
    '公务员': 'civil servant',
    '行政人员': 'Administrative staff',
    '医务人员': 'medical staff',
    '渔夫': 'fisherman',
    '船员': 'crew',
    '非正式官员': 'Informal officials',
    '经纪人': 'agent',
    '厨师': 'cook',
    '安全官员': 'Security Officer',
    '饲养员': 'breeder',
    '建筑师': 'architect',
    '行政官': 'Administrator',
    '军事人员': 'military personnel',
    '设计师': 'designer',
    '大学生': 'college student',
    '艺术家': 'artist',
    '律师': 'lawyer',
    '退休人员': 'retiree',
    '政府官员': 'government official',
    '保安': 'security staff',
    '临时雇员': 'Temporary employees',
    '飞行员': 'pilot',
    '医生': 'doctor',
    '邮箱地址': 'Email',
    '身份证号': 'ID number',
    '行政区': 'administrative district',
    '县': 'county',
    '感谢您对本产品的支持': 'Thank you for your support of this product',
    '您的账号已于': 'Your account has expired on ',
    '成功提交注销申请。除法律法规规定和注销协议约定情形外，我们将为您保留该账号至': 'Successfully submitted the cancellation request. Except for the circumstances stipulated by laws and regulations and the cancellation agreement, we will keep this account for you until ',
    '(从您提交注销申请次日起15日）。在此之后，账号注销申请成功，我们将删除或匿名化处理您的相关个人信息。': '(15 days from the day after you submit the cancellation application). After that, if the account cancellation application is successful, we will delete or anonymize your relevant personal information.',
    '在前述15日届满前，请您不要登录和使用该账号，以确保注销的顺利完成，否则视为您撤销注销申请。': 'Before the expiration of the aforementioned 15 days, please do not log in and use the account to ensure the smooth completion of the cancellation, otherwise it will be deemed that you have canceled the cancellation application.',
    '完成': 'Finish',
    '请完善地址信息': 'Please complete the address information',
    '无法调起后置摄像头，请点击相册，手动上传身份证':'无法调起后置摄像头，请点击相册，手动上传身份证',
    '请将证件对准拍摄':'请将证件对准拍摄',
    '重拍':'重拍',
    '点击拍照':'Take photo',
    '照片必须清晰、完整而且无反光':'Photos must be clear, complete and non-reflective',
    '您必须允许相机拍照，否则您将无法进入下一步申请贷款。':'You must allow the camera to take pictures, otherwise you will not be able to proceed to the next step to apply for a loan.',
    '拍摄':'Take photo',
    '居住信息':'Residence information',
    '第一名字':'First Name',
    '第二名字':'Second Name',
    '家庭姓名':'Family Name',
    '还款':'repayment',
    '去登陆':'Login',
    '上传工作证件':'Upload work certificate',

}

export default en