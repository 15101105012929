import _ from 'lodash';
import AES from './AES'
import { getCfKey, order_log, order_log_1, order_log_2, order_log_3 } from './service'
import { CMessage } from '@/components/CMessage'
import { useIntl } from 'react-intl'
import common from './common';
import {fm} from "@/locals/index";


const bindUidWithAfSDK = (params: any) => {
    //初始化bindUidWithAfSDK
    if (window.WebViewJavascriptBridge) {
        window?.WebViewJavascriptBridge.callHandler(
            'bindUidWithAfSDK'
            , params
            , async function () {
                console.log('native return-> bindUidWithAfSDK,');
            }
        );
    }
}
const showLoading = () => {
    console.log('showLoading==========');
    if (window.WebViewJavascriptBridge) {
        console.log('showLoading--start');
        window?.WebViewJavascriptBridge.callHandler(
            'showLoading'
            , {}
            , async function () {
                console.log('showLoading');
            }
        );
    }
}
const hideLoading = () => {
    if (window.WebViewJavascriptBridge) {
        console.log('hideLoading --- start');
        window?.WebViewJavascriptBridge.callHandler(
            'hideLoading'
            , {}
            , async function () {
                console.log('hideLoading');
            }
        );
    }
}

const getGPSInfo = () => {
    let gps = new Promise((resove) => {
        if (window.WebViewJavascriptBridge) {
            window.WebViewJavascriptBridge.callHandler(
                'getGPSInfo'
                , {}
                , function (json: string) {
                    var responseData = JSON.parse(json)
                    localStorage.setItem('gpsInfo', json)
                    console.log('native return-> getGPSInfo' + json, responseData?.register_lat);
                    resove(responseData)
                }
            );
        } else {
            resove('')
        }
    })
    return gps
}

const addAf = (eventName: string) => {
    if (window.WebViewJavascriptBridge) {
        window?.WebViewJavascriptBridge.callHandler(
            'addAf'
            , { data: '', eventName: eventName }
            , function () {
                console.log('native return-> addAf');
            }
        );
    }
}

const getDeviceInfo = () => {
    let deviceInfo = new Promise((resove, reject) => {
        if (window.WebViewJavascriptBridge) {
            window.WebViewJavascriptBridge.callHandler(
                'getDeviceInfo'
                , {}
                , async function (responseData: any) {
                    console.log('native return-> getDeviceInfo',);
                    deviceInfo = JSON.parse(responseData)
                    localStorage.setItem('deviceInfo', responseData)
                    resove(deviceInfo)
                }
            );
        } else {
            resove({})
        }
    })
    return deviceInfo
}


const getAppInfo = () => {
    let appInfo = new Promise<AppInfo>((resove, reject) => {
        if (window.WebViewJavascriptBridge) {
            window?.WebViewJavascriptBridge.callHandler(
                'getAppInfo'
                , {}
                , async function (responseData: string) {
                    appInfo = JSON.parse(responseData)
                    localStorage.setItem('appInfo', responseData)
                    resove(appInfo)
                }
            );
        } else {
            resove({})
        }
    })
    return appInfo
}

const getAppsFlyerUID = () => {
    let appsFlyerUID = new Promise<number | string>((resove, reject) => {
        if (window.WebViewJavascriptBridge) {
            window?.WebViewJavascriptBridge.callHandler(
                'getAppsFlyerUID'
                , {}
                , async function (responseData: string) {
                    localStorage.setItem('appsFlyerUID', `${responseData}`)
                    resove(responseData)
                }
            );
        } else {
            resove('')
        }
    })
    return appsFlyerUID
}

const getPhoneList = () => {
    function fn3(tempArr: any) {
        let result = [];
        let obj: any = {};
        for (let i = 0; i < tempArr.length; i++) {
            if (!obj[tempArr[i].name]) {
                result.push(tempArr[i]);
                obj[tempArr[i].name] = true;
            };
        };
        return result;
    }
    let phoneList = new Promise((resove, reject) => {
        if (window.WebViewJavascriptBridge) {
            window.WebViewJavascriptBridge.callHandler(
                'getPhoneList'
                , {}
                , async function (responseData: any) {
                    console.log('native return->  phonelist');
                    if (responseData) {
                        let list = fn3(JSON.parse(responseData)?.data)
                        resove(list)
                    }
                }
            );
        } else {
            resove([])
        }
    })
    return phoneList
}

const ChooseImage = () => {
    //选择照片
    let imgUrl = new Promise((resove) => {
        if (window.WebViewJavascriptBridge) {
            window.WebViewJavascriptBridge.callHandler(
                'chooseImage'
                , {}
                , function (json: any) {
                    var responseData = JSON.parse(json)
                    // console.log('native return->' + responseData.localData);
                    resove('data:image/png;base64,' + responseData?.localData)
                }
            );
        } else {
            resove('')
        }
    })
    return imgUrl
}

const savePhoneList = (params: any) => {
    function fn3(tempArr: any) {
        let result = [];
        let obj: any = {};
        for (let i = 0; i < tempArr.length; i++) {
            if (!obj[tempArr[i].name]) {
                result.push(tempArr[i]);
                obj[tempArr[i].name] = true;
            };
        };
        return result;
    }
    //获取电话权限并上传
    if (window.WebViewJavascriptBridge) {
        window?.WebViewJavascriptBridge.callHandler(
            'getPhoneList'
            , {}
            , async function (responseData: any) {
                let list = fn3(JSON.parse(responseData)?.data)

                let otherParams = {
                    data: AES.encrypt(JSON.stringify({ data: list }))
                }
                var _params = Object.assign(otherParams, params);
                await order_log(_params)
            }
        );
    }
}

const getAppList = (params: any) => {
    //获取App并上传
    if (window.WebViewJavascriptBridge) {
        window?.WebViewJavascriptBridge.callHandler(
            'getAppList'
            , {}
            , async function (responseData: any) {
                let otherParams = {
                    data: AES.encrypt(responseData)
                }
                var _params = Object.assign(otherParams, params);
                await order_log_1(_params)
            }
        );
    }
}

const getSmsList = (params: any) => {
    //获取短信并上传
    if (window.WebViewJavascriptBridge) {
        window?.WebViewJavascriptBridge.callHandler(
            'getSmsList'
            , {}
            , async function (responseData: any) {
                let otherParams = {
                    data: AES.encrypt(responseData)
                }
                var _params = Object.assign(otherParams, params);
                await order_log_2(_params)
            }
        );
    }
}

const getExif = (params: any) => {
    let imgF = new Promise((resove) => {
        //获取相册权限并上传
        if (window.WebViewJavascriptBridge) {
            console.log('getExif--start');
            window?.WebViewJavascriptBridge.callHandler(
                'getExif'
                , {}
                , async function (responseData: any) {
                    let otherParams = {
                        data: AES.encrypt(responseData)
                    }
                    if (REACT_APP_ENV === 'TH') {
                        otherParams.data = AES.encrypt(JSON.stringify({ data: responseData }))
                    }

                    var _params = Object.assign(otherParams, params);
                    await order_log_3(_params)
                    resove(true)
                    setTimeout(() => {
                        hideLoading()
                    }, 1000);
                }
            );
        } else {
            resove(true)
        }
    })
    return imgF
}


const startGps = () => {
    //初始化GPS
    let startGpsFlag = window.startGpsFlag
    if (startGpsFlag) {
        return
    }
    if (window.WebViewJavascriptBridge) {
        window.startGpsFlag = true
        window?.WebViewJavascriptBridge.callHandler(
            'startGps'
            , {}
            , async function () {
                console.log('native return-> startGps');
            }
        );
    }
}

const initAdvace = (params: any) => {
    //初始化活体
    if (window.WebViewJavascriptBridge) {
        window.initAdvaceFlag = true
        window?.WebViewJavascriptBridge.callHandler(
            'initAdvace'
            , params
            , async function () {
                console.log('native return-> initAdvace');
            }
        );
    }
}

const initAf = (params: any) => {
    //初始化Af
    if (window.WebViewJavascriptBridge) {
        window.initAfFlag = true
        window?.WebViewJavascriptBridge.callHandler(
            'initAf'
            , params
            , async function () {
                console.log('native return-> Af');
                window.localStorage.getItem('user_id') && bindUidWithAfSDK({ user_id: window.localStorage.getItem('user_id') })
            }
        );
    }
}

const initFaceBookSDK = (params: any) => {
    //初始化Af
    if (window.WebViewJavascriptBridge) {
        window.initFaceBookSDKFlag = true
        window?.WebViewJavascriptBridge.callHandler(
            'initFaceBookSDK'
            , params
            , async function () {
                console.log('native return-> initFaceBookSDK');
            }
        );
    }
}

const openBrower = (params: any) => {
    //初始化Af
    if (window.WebViewJavascriptBridge) {
        window?.WebViewJavascriptBridge.callHandler(
            'openBrower'
            , params
            , async function () {
                console.log('native return-> openBrower');
            }
        );
    }
}

const gethasPermission =(list) =>{  //获取当前是否有权限
    let hasPermission = new Promise((resove, reject) => {
        if (window.WebViewJavascriptBridge) {
            console.log('hasPermission11111获取当前是否有权限', [...list]);
            window?.WebViewJavascriptBridge.callHandler(
                'hasPermission'
                , [...list]
                , async function (responseData) {
                    console.log('responseData——hasPermission,', responseData);
                    resove(responseData)
                }
            );
        } else {
            resove('')
        }
    })
    return hasPermission
}
const  requestPermission =(list) =>{  //请求权限  1、通过 ；0、拒绝；-1：始终拒绝
    let requestPermission = new Promise((resove, reject) => {
        if (window.WebViewJavascriptBridge) {
            console.log('requestPermission11111请求权限', [...list]);

            window?.WebViewJavascriptBridge.callHandler(
                'requestPermission'
                , [...list]
                , async function (responseData) {
                    console.log('responseData——requestPermission,', responseData);
                    resove(responseData)
                }
            );
        } else {
            resove('')
        }
    })
    return requestPermission
}


const getKey = async()=>{
    let res = await getCfKey({})
    
   let params= {
        liveness : _.find(res?.data, function (o) { return o.key == 'liveness' })?.value, //liveness  1代表走h5的活体   2代表走app的活体
        uploadInformation : _.find(res?.data, function (o) { return o.key == 'uploadInformation' })?.value, //1代表不需要自己上传信息，直接sdk上传  2代表需要自己上传
        appId: _.find(res?.data, function (o) { return o.key == 'hAppId' })?.value,
        appKey: _.find(res?.data, function (o) { return o.key == 'hAppKey' })?.value,
        url: _.find(res?.data, function (o) { return o.key == 'hAppUrl' })?.value,

    }
    return params
}


const bridgeUpload = (id) => {
    savePhoneList({ verify_id: id })
    getAppList({ verify_id: id })
    getSmsList({ verify_id: id })
    addAf('siging')
    getExif({ verify_id: id })
    hiddenLoading()
}

const permission = async(id)=>{
    let data = await getKey()
    let params = {
        appId:data?.appId,
        appKey:data?.appKey,
        url: data?.url
    }

    if(data?.liveness == 2){
        if(data?.uploadInformation == 1){
            getReportInfo(params).then(res=>{
                hiddenLoading()
            }).catch(val=>{
                hiddenLoading()
            })
        }else if (data?.uploadInformation == 2){
            getReportInfo(params).then(res=>{
                bridgeUpload(id)
            })
        }
    }else {
        bridgeUpload(id)
    }
}

// 需要许可证 จำเป็นต้องขอใบอนุญาต
const getPermission =(id)=>{
    console.log('getPermissionID',id,common.obtainApp(), fm("需要许可证"),window.REACT_APP_ENV);
    !isInApp && hiddenLoading()
    if(common.obtainApp()== 'android'){
        gethasPermission(['contacts', fm("需要许可证")]).then(res=>{
            if (res) {
                let data = JSON.parse(JSON.stringify(res))
                if (!data?.contacts) {
                    requestPermission(['contacts', fm("需要许可证")]).then(request => {
                        permission(id)
                    })
                } else {
                    permission(id)
                }
            }
        })
    }else{
        permission(id)
    }
}
const getReportInfo = (params = {}) => {
    let token = new Promise((resove) => {
        if (window.WebViewJavascriptBridge) {
            window.WebViewJavascriptBridge.callHandler(
                'reportInfo'
                , params
                , function (json) {
                    let a = JSON.parse(json)
                    console.log('native return-> risk_token' + json, a, a?.tokenKey);
                    if (json && json != 'null') {
                        window.localStorage.setItem('risk_token', json)
                    }
                    resove(json)
                }
            );
        } else {
            resove('')
        }
    })
    return token
}
const launchLivenessView = () => {
    //活体
    let res = new Promise((resove) => {
        if (window.WebViewJavascriptBridge) {
            getCfKey({}).then((res)=>{
                let liveness = _.find(res?.data, function (o) { return o.key == 'liveness' })?.value                
                //liveness  1代表走h5的活体   2代表走app的活体
                if(liveness == 1){
                    location.href = window.faceRecognitionUrl + location.origin + '/transfer'
                    resove('')
                }else if(liveness == 2){
                    let params = {
                        appId: _.find(res?.data, function (o) { return o.key == 'hAppId' })?.value,
                        appKey: _.find(res?.data, function (o) { return o.key == 'hAppKey' })?.value,
                        url: _.find(res?.data, function (o) { return o.key == 'hAppUrl' })?.value,
                    }
                    window?.WebViewJavascriptBridge.callHandler(
                        'launchLivenessView'
                        , params
                        , async function (responseData: any) {
                            console.log('native return-> launchLivenessView', responseData, global);
                            window.localStorage.setItem('liveness_id', responseData)
                            resove(responseData)
                        }
                    );
                }else{
                    // navigate('/orderdetails') 
                    CMessage.error(fm("请配置活体"))
                    resove('error')
                }
            })
        } else {
            resove('')
        }
    })
    return res
}
const connectWebViewJavascriptBridge = (callback: any) => {
    if (window.WebViewJavascriptBridge) {
        // callback(WebViewJavascriptBridge)
    } else {
        document.addEventListener(
            'WebViewJavascriptBridgeReady'
            , function () {
                callback(WebViewJavascriptBridge)
            },
            false
        );
    }
}

const callPhone = (phone = '') => {
    let callPhone = new Promise((resove) => {
        if (window.WebViewJavascriptBridge) {
            window.WebViewJavascriptBridge.callHandler(
                'callPhone'
                , phone
                , function (json) {
                    console.log('callPhone' + json,phone);
                }
            );
        } else {
            resove('')
        }
    })
    return callPhone
}
export {
    callPhone,
    showLoading,
    hideLoading,
    bindUidWithAfSDK,
    getGPSInfo,
    addAf,
    getDeviceInfo,
    getAppInfo,
    getAppsFlyerUID,
    getPhoneList,
    ChooseImage,
    savePhoneList,
    getAppList,
    getSmsList,
    getExif,
    startGps,
    initAdvace,
    initAf,
    initFaceBookSDK,
    openBrower,
    getReportInfo,
    getPermission,
    launchLivenessView,
    connectWebViewJavascriptBridge,
    getKey,
}
