const th = {
    '十八岁以下未成年用户无法获取贷款': '十八岁以下未成年用户无法获取贷款',
    '正规持牌金融机构': '正规持牌金融机构',
    '您最高可激活额度': '您最高可激活额度',
    '元': '元',
    '激活额度': '激活额度',
    '年化费率最低': '年化费率最低',
    '借1万每日低至': '借1万每日低至',
    '填写资料': '填写资料',
    '评估额度': '评估额度',
    '立即放款': '立即放款',
    '申请步骤': '申请步骤',
    '身份证正面': '身份证正面',
    '上传您的身份证人像面': '上传您的身份证人像面',
    '身份证反面': '身份证反面',
    '上传您的身份证国徽面': '上传您的身份证国徽面',
    '手持身份证拍摄': '手持身份证拍摄',
    '上传您拿着身份证的照片': '上传您拿着身份证的照片',
    '身份认证': '身份认证',
    '下一步': '下一步',
    '基础信息': '基础信息',
    '姓名': '姓名',
    '小名': '小名',
    '学历': '学历',
    '请选择': '请选择',
    '市': '市',
    '请输入': '请输入',
    '保存': '保存',
    '详细地址': '详细地址',
    '省份': '省份',
    '戳我定位': '戳我定位',
    '家庭地址': '家庭地址',
    '基本信息': '基本信息',
    '子女数量': '子女数量',
    '婚姻状况': '婚姻状况',
    '贷款用途': '贷款用途',
    '居住类型': '居住类型',
    '居住时长': '居住时长',
    '紧急联系人添加': '紧急联系人添加',
    '直系亲属联系人': '直系亲属联系人',
    '关系': '关系',
    '手机号码': '手机号码',
    '真实姓名': '真实姓名',
    '紧急联系人1': '紧急联系人1',
    '紧急联系人2': '紧急联系人2',
    '工作信息': '工作信息',
    '职业': '职业',
    '每月收入': '每月收入',
    '发薪日期': '发薪日期',
    '公司名称': '公司名称',
    '公司电话': '公司电话',
    '公司地址': '公司地址',
    '借款天数': '借款天数',
    '贷款金额': '贷款金额',
    '到期应还金额': '到期应还金额',
    '收款信息': '收款信息',
    '还未添加银行卡，快去添加吧': '还未添加银行卡，快去添加吧',
    '添加银行卡': '添加银行卡',
    '立即借款': '立即借款',
    '请认真阅读并同意《借款协议》': '请认真阅读并同意《借款协议》',
    '还款日': '还款日',
    '收款银行卡号': '收款银行卡号',
    '确认借款': '确认借款',
    '放弃借款': '放弃借款',
    '到账金额': '到账金额',
    '更多信息': '更多信息',
    '订单详情': '订单详情',
    '等待放款': '等待放款',
    '订单已提交，将在12小时内处理完成，感谢您的耐心等待': '订单已提交，将在12小时内处理完成，感谢您的耐心等待',
    '订单编号': '订单编号',
    '去下载': '去下载',
    '保存二维码下载‘电子签约云平台’扫码可查看放款进度方便还款。点击下方去按钮跳转应用市场下载‘电子签约云平台’': '保存二维码下载‘电子签约云平台’扫码可查看放款进度方便还款。点击下方去按钮跳转应用市场下载‘电子签约云平台’',
    '首页':'首页',
    '我':'我',
    '服务费':'服务费',
    '到期还款日':'到期还款日',
    '您输人的银行卡号码属于敏感个人信息，点击“确定”（以页面显示为准）代表您充分知悉并同意我们基于身份识别、借款服务、完成支付、银行卡鉴权的目的处理您的上述信息。如您拒绝同意，将影响您使用前述服务。':'您输人的银行卡号码属于敏感个人信息，点击“确定”（以页面显示为准）代表您充分知悉并同意我们基于身份识别、借款服务、完成支付、银行卡鉴权的目的处理您的上述信息。如您拒绝同意，将影响您使用前述服务。',
    '银行卡':'银行卡',
    '卡类别':'卡类别',
    '银行卡名称':'银行卡名称',
    '银行卡号':'银行卡号',
    '确认银行卡号':'确认银行卡号',
    '持卡人姓名':'持卡人姓名',
    '收款银行编码':'收款银行编码',
    '确认':'确认',
    '戳我去修改':'戳我去修改',
    '收款银行卡名称':'收款银行卡名称',
    '收款银行卡卡号':'收款银行卡卡号',
    '收款信息有误？':'收款信息有误？',
    '确认贷款订单':'确认贷款订单',
    '贷款订单已申请成功，再仅需1分钟完成签约合同即可放款':'贷款订单已申请成功，再仅需1分钟完成签约合同即可放款',
    '申请成功':'申请成功',
    '合同托管至第三方专业的电子合同平台（“电子合同云平台App”）管理，在线签约合同仅需1分钟即可全部完成。':'合同托管至第三方专业的电子合同平台（“电子合同云平台App”）管理，在线签约合同仅需1分钟即可全部完成。',
    '温馨提醒':'温馨提醒',
    '打开电子合同App签约':'打开电子合同App签约',
    '不知道操作签约合同？查看帮助':'不知道操作签约合同？查看帮助',
    '订单信息':'订单信息',
    '流程图':'流程图',
    '不放弃':'不放弃',
    '您还差最后一步就有获得XXX元的机会，确定要放弃吗？':'您还差最后一步就有获得XXX元的机会，确定要放弃吗？',
    '您还差最后一步就有获得':'您还差最后一步就有获得',
    '元的机会，确定要放弃吗？':'元的机会，确定要放弃吗？',
    '放弃':'放弃',
    '性别':'性别',
    '0':'0',
    '1':'1',
    '2':'2',
    '3':'3',
    '4':'4',
    '5':'5',
    '5个以上':'5个以上',
    '日常使用':'日常使用',
    '健康':'健康',
    '维修':'维修',
    '投资':'投资',
    '儿童学费':'儿童学费',
    '其他（必须由客户指定）':'其他（必须由客户指定）',
    '3个月':'3个月',
    '6个月':'6个月',
    '1年':'1年',
    '2年':'2年',
    '2年以上':'2年以上',
    '兄弟姐妹':'兄弟姐妹',
    '朋友':'朋友',
    '父亲':'父亲',
    '母亲':'母亲',
    '伴侣':'伴侣',
    '还款日期':'还款日期',
    '剩余应还':'剩余应还',
    '网银转账':'网银转账',
    '便利店oxxo付款':'便利店oxxo付款',
    '扫码支付':'扫码支付',
    'VA账号付款':'VA账号付款',
    '便利店PAYNET付款':'便利店PAYNET付款',
    '待还总额（元）':'待还总额（元）',
    '还款倒计时':'还款倒计时',
    '天':'天',
    '去还款':'去还款',
    '请选择支付方式':'请选择支付方式',
    '金额':'金额',
    '请选择展期天数':'请选择展期天数',
    '确定':'确定',
    '我的借款':'我的借款',
    '我的客服':'我的客服',
    '帮助中心':'帮助中心',
    '隐私政策':'隐私政策',
    '关于我们':'关于我们',
    '设置':'设置',
    '已认证':'已认证',
    '账号注销':'账号注销',
    '退出登录':'退出登录',
    '填写验证码':'填写验证码',
    '获取验证码':'获取验证码',
    '登录':'登录',
    '手机号':'手机号',
    '填写手机号':'填写手机号',
    '验证码':'验证码',
    '请填写手机号':'请填写手机号',
    '请填写验证码':'请填写验证码',
    '请输入正确格式的有效值':'请输入正确格式的有效值',
    '欢迎使用':'欢迎使用',
    '申请展期':'申请展期',
    '需要许可证':'จำเป็นต้องขอใบอนุญาต',
    '出生日期':'Birthday',
    '证件号码':'ID number',
    '状态':'状态',
    '账号安全':'账号安全',
    '注销后，以下信息会发生变化':'注销后，以下信息会发生变化',
    '账户相关信息将会被删除，包括绑定信息(手机号、银行卡、身份证、微信、邮箱等)、交易、还款等订单信息(交易单、账单等).后续将无法再使用该账户交易。':'账户相关信息将会被删除，包括绑定信息(手机号、银行卡、身份证、微信、邮箱等)、交易、还款等订单信息(交易单、账单等).后续将无法再使用该账户交易。',
    '账户信息删除':'账户信息删除',
    '账户相关信息将会被删除，包括绑定信息(手机号、银行卡、身份证、微信、邮箱等)、交易、还款等订单信息(交易单、账单等).后续将无法再使用该账户交易。相关权益(优惠券、购物金、红包、乐星、乐黑卡特权等)将会被清空，消费额度将会被清空，后续无法继续享用相关权益和消费额度。':'账户相关信息将会被删除，包括绑定信息(手机号、银行卡、身份证、微信、邮箱等)、交易、还款等订单信息(交易单、账单等).后续将无法再使用该账户交易。相关权益(优惠券、购物金、红包、乐星、乐黑卡特权等)将会被清空，消费额度将会被清空，后续无法继续享用相关权益和消费额度。',
    '相关权益删除':'相关权益删除',
    '后续将不再提供关于该账户的相关咨询和售后服务。':'后续将不再提供关于该账户的相关咨询和售后服务。',
    '账户不可找回':'账户不可找回',
    '注销后账户将彻底删除，无法找回，代表你完全放弃了本账户相关的一切服务。':'注销后账户将彻底删除，无法找回，代表你完全放弃了本账户相关的一切服务。',
    '不再提供咨询和售后服务':'不再提供咨询和售后服务',
    '注销后重新注册，可能会影响额度的获取':'注销后重新注册，可能会影响额度的获取',
    '为防范欺诈风险，若注销此账号后重新注册，新账号在本平台的授信申请审批及额度获取可能会受到影响':'为防范欺诈风险，若注销此账号后重新注册，新账号在本平台的授信申请审批及额度获取可能会受到影响',
    '暂不注销':'暂不注销',
    '开始注销':'开始注销',
    '开始拍照':'开始拍照',
    '选择照片':'选择照片',
    '逾期天数':'逾期天数',
    '请输入姓名':'请输入姓名',
    '长度为18位数字':'长度为18位数字',
    '请输入证件号码':'请输入证件号码',
    '请输入正确邮箱':'请输入正确邮箱',
    '联系人选择':'联系人选择',
    '长度为16或18位数字':'长度为16或18位数字',
    '确认银行卡号与银行卡号不一致':'确认银行卡号与银行卡号不一致',
    '借款协议':'借款协议',
    '您存在未完成订单，不能注销账号':'您存在未完成订单，不能注销账号',
    '职业类型':'职业类型',
    '男':'男',
    '女':'女',
    '第三性别':'第三性别',
    '自有房产':'自有房产',
    '租赁':'租赁',
    '其他':'其他',
    '警察': '警察',
    '家庭主妇': '家庭主妇',
    '企业主': '企业主',
    '教师': '教师',
    '牧民': '牧民',
    '力工': '力工',
    '政府承包商': '政府承包商',
    '机械师': '机械师',
    '司机': '司机',
    '批发商': '批发商',
    '工程师': '工程师',
    'IT': 'IT',
    '服务员': '服务员',
    '研究人员': '研究人员',
    '会计': '会计',
    '营销人员': '营销人员',
    '顾问': '顾问',
    '公务员': '公务员',
    '行政人员': '行政人员',
    '医务人员': '医务人员',
    '渔夫': '渔夫',
    '船员': '船员',
    '非正式官员': '非正式官员',
    '经纪人': '经纪人',
    '厨师': '厨师',
    '安全官员': '安全官员',
    '饲养员': '饲养员',
    '建筑师': '建筑师',
    '行政官': '行政官',
    '军事人员': '军事人员',
    '设计师': '设计师',
    '大学生': '大学生',
    '艺术家': '艺术家',
    '律师': '律师',
    '退休人员': '退休人员',
    '政府官员': '政府官员',
    '保安': '保安',
    '临时雇员': '临时雇员',
    '飞行员': '飞行员',
    '医生': '医生',
    '邮箱地址': '邮箱地址',
    '身份证号': '身份证号',
    '行政区': '行政区',
    '县': '县',
    '感谢您对本产品的支持': '感谢您对本产品的支持',
    '您的账号已于': '您的账号已于',
    '成功提交注销申请。除法律法规规定和注销协议约定情形外，我们将为您保留该账号至': '成功提交注销申请。除法律法规规定和注销协议约定情形外，我们将为您保留该账号至',
    '从您提交注销申请次日起15日）。在此之后，账号注销申请成功，我们将删除或匿名化处理您的相关个人信息。': '从您提交注销申请次日起15日）。在此之后，账号注销申请成功，我们将删除或匿名化处理您的相关个人信息。',
    '在前述15日届满前，请您不要登录和使用该账号，以确保注销的顺利完成，否则视为您撤销注销申请。': '在前述15日届满前，请您不要登录和使用该账号，以确保注销的顺利完成，否则视为您撤销注销申请。',
    '完成': '完成',
    '请完善地址信息': '请完善地址信息',
    '无法调起后置摄像头，请点击相册，手动上传身份证':'无法调起后置摄像头，请点击相册，手动上传身份证',
    '请将证件对准拍摄':'请将证件对准拍摄',
    '重拍':'重拍',
    '点击拍照':'点击拍照',
    '照片必须清晰、完整而且无反光':'照片必须清晰、完整而且无反光',
    '您必须允许相机拍照，否则您将无法进入下一步申请贷款。':'您必须允许相机拍照，否则您将无法进入下一步申请贷款。',
    '拍摄':'拍摄',
    '居住信息':'居住信息',

}

export default th