
/**
 * 该文件是公共配置
 */


/**
 * 不需要判断登陆的页面
 */
export const dontNeedLoginPath: string[] = ['/home', '/', '/login']

export const childrenNum: string[] = ['0', '1', '2', '3', '4', '5', '5个以上']

const maxMonthDay = new Array(31).fill(1);
export const payDay = maxMonthDay.map((_, i) => i + 1)
