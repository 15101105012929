import { childrenNum, dontNeedLoginPath, payDay } from "../Default";

/**个人信息 */
//学历
const educations = ['Sin estudios', 'Educación primaria', 'Educacion secundaria', 'Bachillerato/Profesional Técnico', 'Universidad / Instituto Tecnológico', 'Especialización / Maestría / Doctorado']
//婚姻状况
const maritalStatus = ['Soltero', 'Divorcio', 'Casado', 'Viudo']
//性别
const gender = ["Male", "Female", "Third Gender"]
//居住类型
const liveType = ['Casa propia', 'Alquiler', 'Otros']
//居住时长
const liveLength = ['3个月', '6个月', '1年', '2年', '2年以上']
// 贷款用途
const loanPurposeList = ['日常使用', '健康', '维修', '投资', '儿童学费', '其他（必须由客户指定）']

/**紧急联系人 */
const relation = ['父亲', '母亲', '伴侣',]
const relation_other = ['兄弟姐妹', '朋友']

/**发薪方式 */
const getMoneyType = ["Semanal", "Salario diario", "Mensual", 'Quincenal']
const monthlyIncome = ["Menos que $3000", "$ 3001- $ 6000", "$ 6001- $ 9000", "$ 9001- $ 12000", "$ 12001- $ 15000", "Mas que $15000"]

/**工作信息 */
const jobList = ["Agente de policía", "Ama de casa", "Empresario", "Profesores", "Pastor", "Peón físico", "Contratistas públicos", "Conductores", "Mayoristas", "Ingenieros",
    "IT", "Camarero", "Investigador", "Contables", "Personal de marketing", "Consultores", "funcionario", "Personal administrativo", "Personal médico", "Pescadores",
    "Tripulación", "No-funcionarios", "Agente", "Cocineros", "Oficial de seguridad", "Criador", "Arquitecto", "Administrador", "Oficiales", "Diseñadores", "Estudiantes universitarios", "Artistas", "Abogados",
    "Jubilados", "Camareros", "Funcionario público", "Guardias de seguridad", 'Trabajadores temporales', 'Piloto', 'Médico', 'Otros', 'Minoristas',
    'Personal médico', 'Funcionarios administrativos']

// const jobType = ["Por cuenta ajena", "Autónomo",]
// const jobInfo = {
//     'Autónomo': AutónomoType,
//     'Por cuenta ajena': ajenaType
// }

export const MX: CountryConfigType = {
    aes_key: 7843565827918888,
    adsKey: 'AW-11040999643/QnqMCNWp74QYENuR4ZAp',
    facebookId: '536031615074228',
    download_url: '',
    web_url: 'https://www.moremoney999.com/',
    faceUrl: 'https://testplat.znuogame.com/?lang=es&origin=',
    title: 'Fides Credit',
    palette: {},
    gender: gender,
    imageUploadType: 'oss',
    education: educations,
    dontNeedLoginPath: [...dontNeedLoginPath],
    loanUseType: loanPurposeList,
    childrenNum: childrenNum,
    maritalStatus: maritalStatus,
    liveType: liveType,
    liveLength: liveLength,
    relation: relation,
    relationOther: relation_other,
    jobs: jobList,
    monthlyIncome: monthlyIncome,
    payDay: payDay,
    getMoneyType: getMoneyType,
    bankCardNum: '17',
}