import React, { useEffect, useState } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';


export class CLoading {
    static show = (text?: string) => { }
    static hidden = (text?: string) => { }
}
const CMLoading = () => {
    const [isLoading, setIsLoading] = useState<boolean>(false)

    useEffect(() => {
        CLoading.show = (text?: string) => {
            setIsLoading(true)
        }
        CLoading.hidden = (text?: string) => {
            setIsLoading(false)
        }
    }, [])
    return (
        isLoading ? <Box sx={{
            display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 999999999,
            justifyContent: 'center', height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0
        }
        } >
            <CircularProgress />
        </Box > : null
    )
}
export default CMLoading