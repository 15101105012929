// locales/index.ts
import zh from './zh_CN';
import en from './en_US';
import th from './th_TH'
import es from './es-ES'
import { createIntl, createIntlCache } from 'react-intl';

const messages = {
    EN: en,
    MX: en,
    // MX: es,
    VN: zh,
    PE: zh,
    TH: th,
    PK: en,
};

const cache = createIntlCache();
const intl = createIntl(
  {
    locale: window.REACT_APP_ENV,
    messages: messages[window.REACT_APP_ENV],
  },
  cache
);

export default intl;

export const fm = (id: string)=>{
    return messages[window.REACT_APP_ENV][id]
}
