//router/index.js文件
import { Component, Suspense, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route, useNavigate } from "react-router-dom"
import { routesConfig } from './config';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from "@mui/material";

function Redirect({ to }: { to: string }) {
    let navigate = useNavigate();
    useEffect(() => {
        navigate(to);
    });
    return null;
}

const RenderRoute = (config: RouteType[]) => {
    if (config && config.length) {
        return config.map(({ path, Components, children, redirect }) => {
            return children && children.length ? <Route path={path} key={path} element={<Components />}>
                {RenderRoute(children)}
                {redirect ?
                    (<Route path={path} element={<Redirect to={redirect} />}></Route>) :
                    (<Route path={path} element={<Redirect to={children[0].path} />}></Route>)
                }
            </Route>
                : <Route key={path} path={path} element={<Components />} />
        })
    }
}

//定义的路由函数直接挂载到主页面上去
class RoutesView extends Component {
    render() {
        return (
            <Suspense fallback={<Box sx={{
                        display: 'flex', flexDirection: 'column', alignItems: 'center', zIndex: 999999999,
                        justifyContent: 'center', height: '100vh', width: '100vw', position: 'fixed', top: 0, left: 0}} >
                    <CircularProgress />
                </Box >}>
                {/* <>loading ...<CircularProgress/></> */}
                <Router>
                    <Routes>
                        {
                            RenderRoute(routesConfig)
                        }
                        <Route path="*" element={<Redirect to="/404" />} />
                    </Routes>
                </Router>
            </Suspense >
        )
    }
}
export default RoutesView;