import common from "@/common/common";
import md5 from "blueimp-md5";

const api_key = 'hfu9CkqRMAVdhq2w'

export async function getPublicApiParams() {
    const token = localStorage.getItem("token");

    let appInfo = JSON.parse(localStorage.getItem('appInfo') || '{}')
    let plat = await common.obtainApp()
    const timestamp = Math.round(new Date().getTime()).toString()
     if (!window.WebViewJavascriptBridge  && !window.android) {
        plat = 'web'
      } 
      
    const bd: API.PublicParams = {
        version: appInfo?.app_version || '1.0.0',
        platform: await common.obtainApp(),
        // platform: 'web',
        appsFlyerId: localStorage.getItem('appsFlyerUID') || '',
        bundleId: appInfo?.app_package_name || '',
        app_id: appInfo?.app_id,
        token: token || undefined,
        timestamp: timestamp,
        sign: '',
        app_package_name: appInfo?.app_package_name || '',

    }

    const sign = token ? md5(timestamp + api_key + token + bd.appsFlyerId + bd.bundleId + bd.version + bd.platform) : md5(timestamp + api_key + bd.appsFlyerId + bd.bundleId + bd.version + bd.platform);//  v1签名方式 sign = md5(timestamp + api_key);  、、 v2签名方式 sign =  token ? md5(timestamp + api_key + token) :  md5(timestamp + api_key);
    bd.sign = sign;

    return { bd, token }
}
