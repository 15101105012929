
import React, { Fragment, useEffect, useState } from 'react';
import { Alert, AlertColor, AlertTitle, Snackbar } from '@mui/material';

export class CMessage {
    static info = (msg: string) => { }
    static warn = (msg: string) => { }
    static success = (msg: string) => { }
    static error = (msg: string) => { }
}

const GlobalMessage = () => {
    const [isOpen, setOpen] = useState(false);
    const [severity, setSeverity] = useState<AlertColor>("info");
    const [content, setContent] = useState("");

    useEffect(() => {
        CMessage.info = (msg) => {
            setOpen(true);
            setSeverity("info");
            setContent(msg);
        };
        CMessage.warn = (msg) => {
            setOpen(true);
            setSeverity('warning');
            setContent(msg);
        };
        CMessage.success = (msg) => {
            setOpen(true);
            setSeverity("success");
            setContent(msg);
        };
        CMessage.error = (msg) => {
            setOpen(true);
            setSeverity("error");
            setContent(msg);
        };
    }, [])

    const handleClose = (_, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false)
    };

    return (
        <Fragment>
            <Snackbar open={isOpen} autoHideDuration={2000} onClose={handleClose} anchorOrigin={{ vertical: 'top', horizontal: 'right' }}>
                <Alert elevation={6} variant={"filled"} onClose={e => setOpen(false)} severity={severity}>
                    <AlertTitle>{severity.toLocaleUpperCase()}</AlertTitle>
                    {content}
                </Alert>
            </Snackbar>
        </Fragment>
    )
}

export default GlobalMessage;