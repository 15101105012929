import { childrenNum, dontNeedLoginPath, payDay } from "../Default";

const getMoneyTypeList1 = ["โอนผ่านธนาคาร", "เงินสด", "อื่นๆ"]

const monthlyIncome = ["ต่ำกว่า 6000", "6000~10000", "10000~15000", "15000~20000", "20000~30000", "30000 ขึ้นไป"]

const jobList = ["นักบัญชี", "บริการ", "วิศวกร", "เจ้าหน้าที่บริหาร", "IT", "ที่ปรึกษา", "เจ้าหน้าที่การตลาด", "ครู", "เจ้าหน้าที่ฝ่ายทหาร", "เจ้าหน้าที่เกษียณ",
    "นักเรียนหรือนักศึกษา", "เจ้าของกิจการ", "ตำรวจ", "ชาวนาชาวประมง", "ผู้เลี้ยงสัตว์", "แพทย์", "เจ้าหน้าที่ทางการแพทย์", "ทนายความ", "พ่อครัวหรือแม่ครัว", "นักวิจัย",
    "นักออกแบบ", "สถาปนิก", "ศิลปิน", "เจ้าหน้าที่รักษาความปลอดภัย", "นายหน้า", "ผู้ค้าส่ง", "นักบิน", "ลูกเรือ", "คนขับรถ", "แรงงาน", "ช่าง", "แม่บ้าน", "เจ้าหน้าที่ลูกจ้างชั่วคราว",
    "ข้าราชการ", "เจ้าหน้าที่ของรัฐ", "เจ้าหน้าที่บริหารฝ่ายปกครอง", "อื่นๆ"]

const IDCardTypeList = ["ใบรับรองการทำงาน", "ใบอนุญาตทำงาน", "ใบขับขี่", "รูปภาพสถานที่ทำงาน", "ใบกำกับภาษี", "บัตรประกันสังคม", "บัตรประกันสุขภาพ"]

const loanPurposeList = ['日常使用', '健康', '维修', '投资', '儿童学费', '其他（必须由客户指定）']

const liveTypeList = ['บ้านของตัวเอง', 'บ้านเช่าราคาถูก', 'Others']

export const TH: CountryConfigType = {
    aes_key: 7843565827918888,
    adsKey: 'AW-11040999643/QnqMCNWp74QYENuR4ZAp',
    facebookId: '536031615074228',
    download_url: '',
    web_url: 'https://www.moremoney999.com/',//测试
    faceUrl: 'https://testplat.znuogame.com/?lang=es',
    title: 'Fides Credit',
    palette: {},
    gender: ['男', '女', '第三性别'],
    imageUploadType: 'oss',
    education: ['ปริญญาโทและปริญญาเอก', 'ปริญญาตรี', 'มัธยมศึกษาตอนปลาย', 'มัธยมศึกษาตอนต้น', 'ไม่มี'],
    dontNeedLoginPath: [...dontNeedLoginPath],
    loanUseType: loanPurposeList,
    childrenNum: childrenNum,
    liveType: liveTypeList,
    jobs: jobList,
    monthlyIncome: monthlyIncome,
    payDay: payDay,
    bankCardNum: '17',
}