import { getCfKey, getAppVerison } from './service'
import { startGps, initAdvace, getAppInfo, initAf, initFaceBookSDK } from './Bridge';
import _ from 'lodash'

//获取当前是 android  还是 ios
const obtainApp = () => {
    var u = navigator.userAgent;
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    if (isAndroid) {
        return 'android'
    } else if (isiOS) {
        return 'ios'
    } else {
        return 'web'
    }
}
/**
 * 获取当前地区手机号码前缀
 */
export function getCompanyPhonepPrefix(){
    const env= process.env.REACT_APP_ENV
    let prefix=null
    if(env=='TH'){
     prefix='+66'
    }else if(env=='MX')
    {
     prefix='+52'
    }
    else if(env=='VN')
    {
     prefix='+84'
    }
    return  prefix
 }

const getMaxPhoneLength=()=>{
    const env= process.env.REACT_APP_ENV
    if(env=='TH'){
        return 9
    }else if(env=='VN'){
        return 9
    }else if(env=='MX'){
        return 10
    }else if(env=='PK'){
        return 11
    }else{
        return 10
    }
}
export default {
    obtainApp,
    getCompanyPhonepPrefix,
    getMaxPhoneLength,
    async InitializeAdvaceAndAf() {
        //初始化都在这里
        startGps()

        if (initAdvaceFlag && initAfFlag && initFaceBookSDKFlag) {
            return
        }

        let res = await getCfKey({})
        let appInfoStr = localStorage.getItem('appInfo') || ''
        let _AppInfo: AppInfo = {}
        if (appInfoStr) {
            _AppInfo = JSON.parse(appInfoStr)
        } else {
            _AppInfo = await getAppInfo()
        }
        window.isInApp = !!window.WebViewJavascriptBridge
        let params = {
            adai_key: _.find(res?.data, function (o) { return o.key == 'adai_key' })?.value,
            adai_secret: _.find(res?.data, function (o) { return o.key == 'adai_secret' })?.value,
            market: _.find(res?.data, function (o) { return o.key == 'market' })?.value,
            bundleId: _AppInfo,
        }
        if (!initAdvaceFlag) {
            initAdvace(params)
        }
        if (!initAfFlag) {
            initAf({
                af_devkey: _.find(res?.data, function (o) { return o.key == 'af_devkey' })?.value,
                appid: _.find(res?.data, function (o) { return o.key == 'appid' })?.value,
            })
        }
        if (!initFaceBookSDKFlag) {
            initFaceBookSDK({
                fb_appid: _.find(res?.data, function (o) { return o.key == 'fb_appid' })?.value,
                fb_token: _.find(res?.data, function (o) { return o.key == 'fb_token' })?.value,
            })
        }
    },
    async checkVersionUpdate() {
        let res = await getAppVerison({})
        if (res?.code == 200) {
            let appInfoStr = localStorage.getItem('appInfo') || ''
            let _AppInfo: AppInfo = {}
            if (appInfoStr) {
                _AppInfo = JSON.parse(appInfoStr)
            } else {
                _AppInfo = await getAppInfo()
            }

            if (_AppInfo?.app_version < res?.data?.version && res?.data?.download_url) {
                // if (obtainApp() == 'android') {
                //     Modal.alert('', res?.data?.description, [
                //         {
                //             text: 'OK', onPress: () => {
                //                 window?.APPJS?.openBrower(res?.data?.download_url)		//  APPJS.download(string)
                //             }
                //         },
                //     ]);
                // } else if (obtainApp() == 'ios') {
                //     Modal.alert('', res?.data?.description, [
                //         {
                //             text: 'OK', onPress: () => {
                //                 Bridge?.openBrower(res?.data?.download_url)
                //             }
                //         },
                //     ]);
                // }
            }
            // if (obtainApp() == 'android') {
            // 	window?.APPJS?.openBrower('https://www.baidu.com/')
            // }
        }
    }
}

